<template>
    <div class="myhome-page">
        <div class="personal-center-head">
            <div class="mask-layer">
                <div class="avatar-information-group">
                    <van-image
                        class="profilePicture"
                        round
                        fit="cover"
                        width="0.6rem"
                        height="0.6rem"
                        :src="require('../../../assets/img/logo-sm.jpg')"
                    />
                    <div class="information-group">
                        <p>{{ $store.getters.getUserData.name }}</p>
                        <p>{{ $store.getters.getUserData.email }}</p>
                    </div>
                </div>
            </div>
            <div class="head-background"></div>
        </div>
        <!--        <div class="function-menu">-->
        <!--            <div class="menu-li">-->
        <!--                <van-cell is-link>-->
        <!--                    &lt;!&ndash; 使用 title 插槽来自定义标题 &ndash;&gt;-->
        <!--                    <template #title>-->
        <!--                        <van-icon name="coupon-o" class="menu-icon" />-->
        <!--                        <span class="custom-title">修改信息</span>-->
        <!--                    </template>-->
        <!--                </van-cell>-->
        <!--            </div>-->
        <!--            <div class="menu-li">-->
        <!--                <van-cell is-link>-->
        <!--                    &lt;!&ndash; 使用 title 插槽来自定义标题 &ndash;&gt;-->
        <!--                    <template #title>-->
        <!--                        <van-icon name="eye-o" class="menu-icon" />-->
        <!--                        <span class="custom-title">修改密码</span>-->
        <!--                    </template>-->
        <!--                </van-cell>-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="exit-button">
            <van-button color="#D43030" :block="true" @click="logout">退出登录</van-button>
        </div>
    </div>
</template>

<script setup>
import usePublic from "@/hooks/usePublic";

let { $route, $router, $store } = usePublic();

let logout = () => {
    $router.push({ name: "Login" });
    $store.commit("delToken");
};
</script>

<style lang="scss" scoped>
.myhome-page {
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;
    .personal-center-head {
        min-height: 140px;

        .head-background {
            min-height: 140px;
            background: url("../../../assets/img/background/my-bg.png");
            position: relative;
        }
        .mask-layer {
            width: 100%;
            min-height: 140px;
            background: #df5f0b9e;
            position: absolute;
            z-index: 1;
            padding: 40px 30px;
        }
        .avatar-information-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            .profilePicture {
                border: solid 2px #ffffff70;
                margin-right: 10px;
            }
            .information-group {
                color: #fff;
                p:nth-child(1) {
                    font-size: 20px;
                    padding-bottom: 10px;
                }
                p:nth-child(2) {
                    font-size: 12px;
                    color: #fcac77;
                }
            }
        }
    }
    .function-menu {
        margin-top: 10px;
        min-height: 50px;
        background: #fff;
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .menu-li {
            width: 100%;
            border-bottom: solid 1px #eee;
            .menu-icon {
                font-size: 16px;
                color: #df5f0b;
            }
            .custom-title {
                font-size: 14px;
                color: #000;
                padding-left: 10px;
            }
        }
        .menu-li:nth-child(2) {
            border: none;
        }
    }
    .exit-button {
        padding: 15px;
    }
}
</style>
